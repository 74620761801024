import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  screen,
} from "../components/variables";
import FaqRow from "../components/faq-row";

const Wrapper = styled.div`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto 110px auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .heading {
    font-weight: 900;
    line-height: 1.22;
    position: relative;
    font-size: 2rem;
    @media ${screen.xxsmall} {
      font-size: 3.5rem;
    }
    @media ${screen.small} {
      font-size: 5rem;
      line-height: 1.09;
    }
  }

  .description {
    max-width: 1030px;
    margin: 35px 0 0 0;
    @media ${screen.small} {
      margin: 70px auto 0 auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      line-height: 1.4;
      margin: 38px 0 16px 0;
      font-size: 1.25rem;
      @media ${screen.xxsmall} {
        font-size: 1.52rem;
        margin: 38px 0 23px 0;
      }
      @media ${screen.small} {
        font-size: 1.55rem;
      }
    }

    p {
      margin: 0 0 21px 0;
      font-size: 1.02rem;
      @media ${screen.small} {
        font-size: 1.06rem;
      }
      &:last-child {
        margin: 0;
      }
    }

    a {
      color: ${mainCyan};
      text-decoration: underline;
      @media ${screen.withCursor} {
        &:hover {
          color: ${lightPurple};
        }
      }
    }
  }
`;

const TermsAndConditionsPage = ({ data }) => {
  const { heading, description } = data.content.data;

  const pageURL = `https://www.k9swim.com.au/terms-and-conditions/`;

  return (
    <Layout>
      <SEO title={heading.text} url={pageURL} />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="heading">{heading.text}</h1>

          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>

        <FaqRow />
      </Wrapper>
    </Layout>
  );
};

export default TermsAndConditionsPage;

export const dataQuery = graphql`
  {
    content: prismicTermsAndConditionsPage {
      data {
        heading {
          text
        }
        description {
          html
        }
      }
    }
  }
`;
